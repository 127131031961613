import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Structural reinforcement",
  "description": "How to use structural reinforcements on your packaging for safetier shipping.",
  "author": "Zhang Lin",
  "categories": ["shipping-packaging"],
  "date": "2022-05-19T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Transport-Modes", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Structural reinforcement refers to the materials added to strengthen the product structure to meet the new requirements of use and safety, and to save costs and reduce investment. `}</p>
    <p>{`Product reinforcement is used to protect the integrity of the appearance and functions of the product. It can even protect the surface of the product packaging under certain conditions. In the case of long-distance transport, we certainly need extra structural reinforcements to keep our products protected. We will not let our countless days and nights of hard work be in vain, nor will we let our customers' expectations come to nothing. `}</p>
    <p>{`To do so, every product will be protected based on its individual characteristics. Some products need “soft protection” (such as `}<strong parentName="p">{`bubble wraps, shielding bags, and anti-static foam`}</strong>{`), while others need “hard protection” - structural reinforcement (such as `}<strong parentName="p">{`EVA foam, carton with integrated folding liner, and blister tray`}</strong>{`). `}</p>
    <p>{`Without structural reinforcement, the products will run around in the packaged box and collide with each other, and there’d be a huge risk of overweight products falling apart during the transportation process. `}</p>
    <p>{`Looking into the structural reinforcements or “hard protection” materials mentioned above, the EVA foam is one of the most commonly used one. EVA foams are sturdy, are relatively more costly than the other materials. Cartons on the other hand, are more cost efficient and hence are used as a cheaper alternative. `}</p>
    <p>{`Purchasing a single reinforcement material might seem like it's a large budge to assign to. However, keep in mind that the materials are going to be used for the whole batch, and are to be used by several products. Hence, the cost for structural reinforcement for each product will be small. `}</p>
    <p>{`The process of the design and preparation of your packages will be assisted by our team in NexPCB.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Pre-design and packaging solutions`}</strong>{`: packaging designed by our graphic designer.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Intermediate process`}</strong>{`: order will be placed by our purchasing department.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Final stage`}</strong>{`: assembly will be done by our workshop staff.  `}</p>
    <p>{`We will communicate in advance, for example 1. For EA, we have a default packaging method. The customer will specify the packaging method to the PM, who will then convey it to me. 2. The customer for the Alxedo project would not know what packaging method to use. I would make the proposal first and then show it to the PM who would communicate and determine if the customer agreed to adopt it. `}</p>
    <p>{`The overall process of prototyping to mass production and delivery:`}<br parentName="p"></br>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "882px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.97683397683397%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABJ0AAASdAHeZh94AAACLUlEQVQozz1QXUhTcRw992NO95XTzaQak8ixzZqatxS1h60hmuggmGINTcsEyZGQD4HEMioHEzcwX9pWrlIJ51MP+VJUVBhLNknDB5G5aMk1Ny2qh/Ifd0Tn8fwO5+OH5WgM6WQSE6EQHQqF2BJLPUUIgYCHk1N0wO9n/X4/EwwGmMD9CVrghfulgSv04+lpejYchtfrxX/EIu/z+fWEzON2s2aLmVqNRiSf42vq5YXInuGbN0SB4D0mPDNDjd0ZZ8d9XlHk9ZvcpcXFgvkXLyXO/v5MwODgYMZLo9EAfDw+/Xtrq1NInYqtYWvxXUP8QzTMryw5UtvfQZ4/YoS+m2+fMkueYTqVSLjSyeTcry8bx39s8CCfHtDkXzlDkQbwjYxomy0WqeqQQYKT49modku6nZf31VVxcqvVKgNrE6PWlwOUiWzmE4pbQ0OqsdFRTXWZSZpdbMoBCA0XEQFg9doDEhj1uhwA1GxnvTTlMLVunz3cNddkVKHvCW3r6RPtdB0zf+up6eVb9TqkCdVi0rJ7lXIxnATP7Jw81WY487XN2LFiUSqAJgA6Dh4ACxetku2O8u6dc+UD8zWKAtwlVMu128x6m+n0ZvtR16rtICdMa9wvzwLAQFnF8I5S2W5n6YU/HUd6iU2d+7GuEJAaucz+9qvXxQBo4ZdUkSHP3twoH/N5xUBlVkZwyiuadLvEZRVcXnGxLr+2qlJlf5Wkf56vwG57CUizGomGQvwFsMDK/yzKZL0AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9b7fa3085a9621725cc877fa89d66aca/652f5/structural-reinforcement-14.webp 259w", "/static/9b7fa3085a9621725cc877fa89d66aca/c29d2/structural-reinforcement-14.webp 518w", "/static/9b7fa3085a9621725cc877fa89d66aca/9d646/structural-reinforcement-14.webp 882w"],
            "sizes": "(max-width: 882px) 100vw, 882px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/9b7fa3085a9621725cc877fa89d66aca/a2ead/structural-reinforcement-14.png 259w", "/static/9b7fa3085a9621725cc877fa89d66aca/6b9fd/structural-reinforcement-14.png 518w", "/static/9b7fa3085a9621725cc877fa89d66aca/90712/structural-reinforcement-14.png 882w"],
            "sizes": "(max-width: 882px) 100vw, 882px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/9b7fa3085a9621725cc877fa89d66aca/90712/structural-reinforcement-14.png",
            "alt": "Project workflow",
            "title": "Project workflow",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <h1 {...{
      "id": "common-methods-for-structural-reinforcement"
    }}>{`Common methods for Structural Reinforcement:`}</h1>
    <h2 {...{
      "id": "shock-resistant-materials-are-used-to-enhance-structural-stability"
    }}>{`Shock-resistant materials are used to enhance structural stability`}</h2>
    <h3 {...{
      "id": "honeycomb-cardboards"
    }}>{`Honeycomb cardboards`}</h3>
    <p>{`The honeycomb cardboard was constructed to follow the structure of natural honeycombs. Honeycombs are lightweight but strong. This is due to the unique continuous hexagonal layers in the structure. Honeycomb cardboard is a rigid, compact composite material with good thermal and sound insulation. Honeycomb cardboard is usually used as a shock absorber since it suppresses vibration well. This reduces the breakage rate during transportation and its application might also replace wooden boxes. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "310px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "94.5945945945946%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAEhUlEQVQ4yxXL/VOSBxwA8K+KuuxF1JXsuk7FfBuQqagXThEVMUMSQ7ESkJcnkOcBModYOHXzBVGEhzdBeREUrUkpGmhpzc68XXXduuuH1W79Mbt9fv+ATCI+ODjABhAtNhCLbauVimYmQz90//PnT8+ebgyiyIu9PWm/KDfn3PTUuNuJM2orl72LZtOkzWKCrWdbdtzBbmG5nDav02oYRL2Ljm/f/nW7cFpJntHwczi8iqEq3GIKh4Ok3JxgcPnPkz9QxZ1fx0YgElkfHh7e3HwSCfmHtQqVpOfV4YHVupB3iaRB1X9/+fL27RsXbnqAyqvpdPOcKRGPlZAvNjcybPgCRNZWY7Ht2PaWYUh36UJmTxc3kYhrMPX01OTXr/8YR/QMOlXQwVkJ+Ewm09ra6oWcTEpZ8cuX+0G/F6Z/G3v85Il51lRaXMhuaozHdzfWQ6MGXWx7y+FwspuYDpv101+fjAa9Qirq7xd1cDmJxK5aqTjzXQoseV1LXhemRjSY6tWrA9+yp4JWKrrTc3JyHAws7e/FDw8PGxsYNVVXX78+DAWXH+l1XC7nVFqKWHQHBrF7Qi5LJb61l3hut1upPxaVU8vevX83MzXRWFc9Nzvb1dXJvdF2dHQ0dF+Tf/G84FanGh0wTU0MKOUguX0rn0QcQCSxnR0+/2bdtdoXL/fHx39JT0169NDw9uRocmwkvhNTKKQAoFQi7z+8X5ib4XJYlRVXoKyosPIq1bPoWnI7DIMDdovJFwiQyQV2u/3jxw9SkbC2ktIvEROJZxFEnkgkGhuunUpLmpqcsOMW4LBb19cjWlRFpxY31NBCwWXz3NzGxsbx8TGTWUdIhrGx0Y3HkfHR4QWLmUIpLSzI8/t9+MJ8VXkZhFZC8/PzNztu8DvaPW4nbjFPjhvX1iLV1XQi8Zzf73vz5kjU28WqoxsMeplUHN38XatRAwCv4zq43W6Hw+Vd9ASXvYYh3cXzWQb90PTMNI/XvhoO77/Yr7xKSQL4bXJ8yeMQ8No5rc0AUFFBGzEMAYrIw+FVs3mut5t/Op3AaW1OxHdx66xMJMRxW2FhAQC0tbFHjSPJAC2sxr6+28IevuqePCebCIyaqp3n+2KxLDvrXBOrYXd3Vy4Vkb7PrCyn1TfUk8n5iEJmND5MT08jkXJDIb8WUwp412uqq5KSADQaLBp9Ku7r7RN2RqNRNapOSYazZzNmzbOh8IrHZZX19fxAys3KynI47K2tTQCgQvpRVMXntYN1wSLgtbHrqzo4zKnpGQKBQCRm+vz+QMDXzPrpdHpyXd01gUBgND5qafl/ksl5Pp+7t7uzpaEaMFR9ueASAMhk/Rqd7sqVskAgGFwJE4mZqakEnU4bWQvLJXdZzHoAyM4mSqUSKrUEACZG9UCjUQCA38lzOWxqRIxIhE6nO78gn0ajbG5GcdyWlXmmtORyj7Cbe6MdUUiLi4sAoJZe/uA+BqmpBKVSubIS5LCbMtJA2C1wuT2iu8IljxfDsJSUJADQ6bBFt72ZyegV8DMyTqWnJmsx1eWiwv8AK7HFGEtA/u8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7a361d2a5497e37a0760584cf597d758/652f5/structural-reinforcement-01.webp 259w", "/static/7a361d2a5497e37a0760584cf597d758/cb91f/structural-reinforcement-01.webp 310w"],
            "sizes": "(max-width: 310px) 100vw, 310px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7a361d2a5497e37a0760584cf597d758/a2ead/structural-reinforcement-01.png 259w", "/static/7a361d2a5497e37a0760584cf597d758/5fad2/structural-reinforcement-01.png 310w"],
            "sizes": "(max-width: 310px) 100vw, 310px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/7a361d2a5497e37a0760584cf597d758/5fad2/structural-reinforcement-01.png",
            "alt": "Honeycomb Cardboards",
            "title": "Honeycomb Cardboards",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "adding-parts-to-enhance-structural-stability"
    }}>{`Adding parts to enhance structural stability`}</h2>
    <h3 {...{
      "id": "paper-edge-boards"
    }}>{`Paper edge boards`}</h3>
    <p>{`Paper edge boards are lightweight and small structures to assist the product’s protection against both compression and moisture. They improve the packaging quality for transportation, enhance the stacking strength of the carton, protect products from damaged outer packaging and effectively protect the edges and corners of the products. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "626px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.826254826254825%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABJ0AAASdAHeZh94AAADI0lEQVQozxXM21IadwAH4H2tTtuLziS1jo0wI2ljQmzJeIiNaGhBAyLhuAu77HJYdpfDLgjKQfFcjZkaFRVRTJNpZnqRXITsTGf6COWm//11+j3ARyVe/w6u20X05hrMzRUir68R63bAXl6AaZ8jenmB6FnLoC/O4amsENYf6css3df2Gv3a25v++rs3/b0P7//RWidkNsG+p/ira8Q6HdCXbTDdK8ROXiF2eozg2SmY89b/GejTYzBnZ4a3XMO8/Sn4MA0+5kWle461d29Qe3tjBGtlWH0Lnyi2fYn4VQeRThvxg32kDw/BbG8hvLON56+OED76DcGXL43A0RGWylXDPj5l0Es+g1lcMIrrRaP65x+GdHxIXEoSYwF3j4qcthBstRDe3we9vQ15bR0n3S7EtXXMb2zC/esefLs7WDzYgzObxU8/PILj8SRS3iUo9nFU68uIb1TJL5KASdrfozwHL/Ds4AW8zSYSG5v4amgQkw9HcVopobS7C0d9FRPFAuz1CuypBCasVsxYRsDbbMj4vahm41DSceIQopiOx3rUTL2BuWYTT7e28Ly8gtlAAJ998Tlmp8ZRzWXQKJfAVZbxpJSH1e/Do3uj8Ji+xejgLTjnXMiEn0FifMTHc5hOp3qUTdMwsVLBzFoDM6t1OMs12KQ8hlgW9yNB+DkGDS2Pnd0t+AUe3w2bMG+2wD23gIjXCyXqRSnoISGBx7SU6VH3FAnWfBa2ooZxVcOYWsRYsYQHmoa7+SyGpRR+jNFg0wJkgYfL6QfnY6A9eYxmhsWqLCITZYkrI8ORy/Uok8ARc5InFkkkDws5cl8rkO/VPDErGWKWRTKSl8kdRSQWMUFccYGIYYYkQx4iiUmiyBoRs8vEX1r915lX8bOsfKRuB7wYYCMwp3iMSGmYpDTuSCkMySkMyimMFGRYtBxMqoIpUQAfj8DJCXAXyojUNxGqNrFYrGA+p8IhKX9RXzpn9Vthnz4QC+lfc7Q+wEf1wQSrf5Pi9NtJTh+Sk/pwVtSHs5J+N5nQH/hD+iQT15fUou4rruhutawvqKVPrqz6t0vKtf8D7wdGDrPhzmQAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b1902a21ea6270653b50bc799d976e2a/652f5/structural-reinforcement-02.webp 259w", "/static/b1902a21ea6270653b50bc799d976e2a/c29d2/structural-reinforcement-02.webp 518w", "/static/b1902a21ea6270653b50bc799d976e2a/63b03/structural-reinforcement-02.webp 626w"],
            "sizes": "(max-width: 626px) 100vw, 626px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b1902a21ea6270653b50bc799d976e2a/a2ead/structural-reinforcement-02.png 259w", "/static/b1902a21ea6270653b50bc799d976e2a/6b9fd/structural-reinforcement-02.png 518w", "/static/b1902a21ea6270653b50bc799d976e2a/af590/structural-reinforcement-02.png 626w"],
            "sizes": "(max-width: 626px) 100vw, 626px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b1902a21ea6270653b50bc799d976e2a/af590/structural-reinforcement-02.png",
            "alt": "Edge board applications",
            "title": "Edge board applications",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <p>{`Other types of edge boards: `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "595px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.003861003861005%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABJ0AAASdAHeZh94AAAC7klEQVQoz0WSa28TVxCG/df6D2qpP4MP/YaQKlRVfGqrlJYiKKaAq1QBQpzErogJuTg0TmLHoY6V4Ls3Zu147fX9vruz56nW9DLSaEbvPBodnXd87rCIrW8izT1U+wC6hzjXr3GMGHb9NXY9ijTeLnQxtnEauziNt6j2OxxjB9d8hxi74lxvo6xeyucBjAu41pj+x3MqJ2GmZg47t4RrHuKFaw2opbe5Ol5HrpaxK0Hq5zHOgjcxL3dxrKmIsYN03qd89JJUzyK8uX+byLc3eHP/Kxq5JG71GY3UMqehAPHgHU5f/kA1k2ReXsGpbVI42if+yy1e3rnB6r0lseox3MFFyqfMA/SzVU5eLKH/tYU9bsHsGlX+iXr8R46Ct5mYJUAtXmsVA9jlJ4t+YpZZu3uL7MGGYO7jtFMpnwxLjLUwzVyYXiWKmY/QvHjOQIviNHYwCxFa+U2My1dcZ54zq+1g1aK0PoToFP+gnQvRLYbFY5XVSfnceYd5M8GwFmek/8nESDJtJph18zDKMdAPKZ2FqGYiNHJbyLCI088zvj5mqMeZtt4zb6dFBlmUa6d8juG5tgeTKkiTvhanmw2jyj9jJO9hluIw0cBuoCYfsQoPsQoPwG6BpTOrH6MfPJRR5gFqmP1kykX0EZt3v+Yo8oLfv/mSk1AA9CBDbR8tmyV/GqNZrSx+0amu4ujrWBZUk1vko49p5TNiG4e4vXNv4bHKxpbV2nc3VSL0SBUSe6qtpdU8u6TETHg7lDUbq0omoS4Ot9U4G1CNxPcqtfFY1RMbSmZDjxG3fYR0PFM6KZmXgtIvrMpYW5fRVUTm1TWxtWWxKssyLL2Sbm5FRtqGGOlfZVp4KpN8QJrpJ9Ivri2y/WHFnhZ/Q031U9+nY4DxeMSVVqGmV7HmM/6NwaBHqVhYzEyz9Z8uIrSaBlqlTNts4TjiyZc+lOt3XdcP+OWf6qUrth+lFr1tO35xlV95M48X53/OXTCfA18oVz77G85WSte38jPpAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/770ce6767dfa0d855ce16cdcf2ffa075/652f5/structural-reinforcement-03.webp 259w", "/static/770ce6767dfa0d855ce16cdcf2ffa075/c29d2/structural-reinforcement-03.webp 518w", "/static/770ce6767dfa0d855ce16cdcf2ffa075/536c8/structural-reinforcement-03.webp 595w"],
            "sizes": "(max-width: 595px) 100vw, 595px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/770ce6767dfa0d855ce16cdcf2ffa075/a2ead/structural-reinforcement-03.png 259w", "/static/770ce6767dfa0d855ce16cdcf2ffa075/6b9fd/structural-reinforcement-03.png 518w", "/static/770ce6767dfa0d855ce16cdcf2ffa075/3dd3e/structural-reinforcement-03.png 595w"],
            "sizes": "(max-width: 595px) 100vw, 595px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/770ce6767dfa0d855ce16cdcf2ffa075/3dd3e/structural-reinforcement-03.png",
            "alt": "Types of edge boards",
            "title": "Types of edge boards",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "pp-packing-strap"
    }}>{`PP packing strap`}</h3>
    <p>{`As one of the light plastic strap, the PP packing strap is mainly made of polypropylene drawing grade resin, featuring good plasticity, strong fracture tension, high bending fatigue resistance, low density, low value of specific gravity, good tensile impact performance, and are easy to use. These PP packing straps could be either translucent or opaque. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "659px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.28185328185329%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACYElEQVQozzWPyU8TcQBGx4NKoNvMb19mplOgULFQrE1YBUShILITqDQYQBFRIjtYLRpWBS6o7JoAiXgw0QSNJiauRw+ejVH/FlOIybu+vO9TJKeHGIK5Da4LYlmybj4a6Wl7u7vLLKM30/PqVKZwm9bAlu9s7Z8vN2/3n4caSnXrCmfkEMGoqXOOQE5RdvV022RsZHluxu5I2Q/5u3XIGq6x6Hz8RumvbwOpljSl8JhSEZwKRjkjuqC6JFRVCyMllaNNz588rmusr6Twvd+T5s1MH9vJKir//enqSG8pUFG6ZbgNoRiC6onZRHIsBeYIlA3XRIbaX25seH0ZmxnuYQl4ZJS33J0fLPzxrtOUzK0nsh5DKrqggmHBkOCYIZB2wiqL109NT0zdiZVwvJ+hB3JCsn8rVJz393NbR3NAU6FlcFNyy5CK5EQwzChkFCKn018RCMdb9lZXwtWVswKNm5RFJ/G5KzsP8l9vhoEGTJ0bMiEffGaYU8QIZAQS1RXsPnN9tm9rebncLV5QRyC/QosuNV30//xQlZerQw0kbgpqSpYoEwwIBhQDAlTdwwvGqlY3lnq7u+45jg6awtHxMLW45uv26VjfyZRkl2CJjKBIMmxIpmCoIqhipGo2u1WQ1rrY+Wxt5VKWb812xBvuSr4wvjDke7MeJEgj6KCBACNQcqwLqiDgQsAFoctls/naQ3PbM/FbA/eTlMu+XKVloaEx+H0vuyDInU6VE0jRf5kRyUlCBsCpOuxQwopY/ebTR6PBwKw9CddOeKuaP66n97Qax47bCXQhqB6U1EOfEfgPFDGP2cym3wAAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/4a4386d4def76536fbfb9a4652f15f36/652f5/structural-reinforcement-04.webp 259w", "/static/4a4386d4def76536fbfb9a4652f15f36/c29d2/structural-reinforcement-04.webp 518w", "/static/4a4386d4def76536fbfb9a4652f15f36/d2334/structural-reinforcement-04.webp 659w"],
            "sizes": "(max-width: 659px) 100vw, 659px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/4a4386d4def76536fbfb9a4652f15f36/a2ead/structural-reinforcement-04.png 259w", "/static/4a4386d4def76536fbfb9a4652f15f36/6b9fd/structural-reinforcement-04.png 518w", "/static/4a4386d4def76536fbfb9a4652f15f36/6db71/structural-reinforcement-04.png 659w"],
            "sizes": "(max-width: 659px) 100vw, 659px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/4a4386d4def76536fbfb9a4652f15f36/6db71/structural-reinforcement-04.png",
            "alt": "PP Packing Strap",
            "title": "PP Packing Strap",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <p>{`Use of packing strap: `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "654px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.05405405405405%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABcRAAAXEQHKJvM/AAAC/klEQVQoz13SyU9TcRAHcP4YE2M8mRhxCXE5sEUWLciiIIK8AAKCIIVS2up7dIdaC61YRIpdUKGlKpUSTQGrUApq1LgdBMEYjVFS6YLv0d/XtIgxzmEOc/hkJvNN0N2bx6U785DYfHi7/A2htQhWVkNYWV1FIBiMvvv0Fc6Hk5dFF0VFDsvVignnAGWxmSiTzUYJlVpKrOykGLWa6u69Sl00DFAJ+K+4X2Fw7BoIIVhfX4+NyMR9O/qVTbirF8Mqq4RRXIx+dSO6ZHxoNXJ0GIzo6tZCqZIhgUSjcehX+CdeOtSYMdZjur8VocD3OBZrfpclOKrjc1NWDTcoLeOMDSlccX4O11KWyRkbD3OahmOcoSmX627I4uIbsmshLPpGMKkuwLgkA26Ghy8fXv0F51yW8Fh3C5m8zpAheTnpa0wmWbwiQh3nEX3VPsIUJZIr9SlEX5dC4mBo5Qse6crhkefCI+XhIZOFpZfev+DsvRsRt76VeM0qMqygYDx7AMKSdEjLU6E+uQu1adshLdkLaWkS4mA48A3ThkpMKXMxcGY/JjsK8fQmg++fF+PgE6c54tAIyHivktzVCTGsqIG2Ngedlenoqc+A5EQSzmXuQH32zg1wbfUHnhiq4ZHz4BBm4AFzBMPNqfj4Zu7PyeaI29hOJswdxGNSwj9ixC1pGbpqDqEiPxMXSvaj7eg2CPJ2/QGDAXh7auEznIbPUI5xWQGG+GlYeu2Pg8/GrBF3j4TM3NYSr0WF56N9sIgLISvdg90H8lHFO4jG9C2oS98a+3I8GliYccJ1IRtDgkzYRdlwas5i+f2LzQ2Dbl0T67PK2Kl+mvUPKlizuICVlyayxdlpLD93J1uXs5utyktmE2J5i1V0nYN/qAMTtk4svJ5DOBLZ+AgQdY3cxhV5G+x6BqMGCR6bFTCJCiE7nYTmU6lori4CI2mGWCzcOHkTjQWZ/BNyEt0IqW/WZ22XMkKVQt4uFZyn7XoZfU1USrdV59MCkYRWay/TSpWCpmkJ/RsDE35kyWjkMwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/22438390bbf90bcd95dcfc7d9f79ea36/652f5/structural-reinforcement-05.webp 259w", "/static/22438390bbf90bcd95dcfc7d9f79ea36/c29d2/structural-reinforcement-05.webp 518w", "/static/22438390bbf90bcd95dcfc7d9f79ea36/d7085/structural-reinforcement-05.webp 654w"],
            "sizes": "(max-width: 654px) 100vw, 654px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/22438390bbf90bcd95dcfc7d9f79ea36/a2ead/structural-reinforcement-05.png 259w", "/static/22438390bbf90bcd95dcfc7d9f79ea36/6b9fd/structural-reinforcement-05.png 518w", "/static/22438390bbf90bcd95dcfc7d9f79ea36/68e9c/structural-reinforcement-05.png 654w"],
            "sizes": "(max-width: 654px) 100vw, 654px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/22438390bbf90bcd95dcfc7d9f79ea36/68e9c/structural-reinforcement-05.png",
            "alt": "Use of packing strap",
            "title": "Use of packing strap",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <h3 {...{
      "id": "wooden-frames"
    }}>{`Wooden frames`}</h3>
    <p>{`Wooden frames are easy to make. They are strong materials with good durability, elasticity, shock and vibration resistance, and are relatively low in cost.
Wooden frames provide excellent protection for products in long-distance transportation and are usually recovered for re-use. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.02316602316603%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACtUlEQVQoz5XSW0/aYACA4f7GJbtZliVbvJjJknllMi+WJXOL2xzO6Dwwp05F1ECZMsthCooUWxALGsqphQI9QimHwlcE7LdkLrvY3Z6r9we8CPzNNG+H/d4NaMtZUk6ft2Su26y1ZJYJ7ZTJffEqqDIXjSKli3mg8Te6Oujp5m0fkTJk2rPM43aF3JXPt/igtYBZON8si33iT1cbl3s1wlYnt8r+WQnf6LN+I+8xct5u2n2jcUgl/kPJRErRQ4mwD8RYg3LUiHVQOG3nT7SES4psAfHakOgOG+rQ3ivbi7aYhhBWCIcu0kiFwpRUQEoe8ZFtPn5ALD3F556ELI/y/mXxbA2fG8n5lypnG3o+UKfQxOZ49nBGTmAyuWuoHFK+cCccb6+c7yV8XU2gZf9sk/bJ4a/88ULzGusyp102pF466klXPb7bVyiNxoAQb9I+o84hNSYqXB1nfFYh9KUSWKj4LWrUpkbtYmChQaFyyCri31rZYClsq8b2Ut8/HFse054lIYYa9SJSiroji6Np52uecJTjWHz1uZz06mK6kTurRe1Fr6XH4bArACHRSmF98cIohRVioxnfHnYUhCPdSdfHYsShJvYHEqXFbCDn62WxanilQW7qSVQJWTP2ccb5skra8PmRIuHKHm/gMw+Hhoaw525yZYzxzhX2J4np+9TSSGp9LLM9UfLMtJOoRrku1sYT26+EsK157Satz06nHxQOJqWT+UFHQdgze3DqHu18w7in8c+jkcXRDDolR2zK+RagD0H+J2ACOhsUQisg54WdAgR8JYZyP+eGQEN6ulog0KTznUpsgqwXMEdDIV6l3OmdCaOAm0YdDlq6KqSwxTYbMSE0IeyBrsZnb4d9BJomhLBZSXFHi329dnerATrVXHTQUe/W/bMw/Bfytwa9rnk7hP/jF3/IglYv3d98AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/232d72cda7bf4ada3514ef7070d71140/652f5/structural-reinforcement-06.webp 259w", "/static/232d72cda7bf4ada3514ef7070d71140/b0a15/structural-reinforcement-06.webp 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/232d72cda7bf4ada3514ef7070d71140/a2ead/structural-reinforcement-06.png 259w", "/static/232d72cda7bf4ada3514ef7070d71140/0b533/structural-reinforcement-06.png 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/232d72cda7bf4ada3514ef7070d71140/0b533/structural-reinforcement-06.png",
            "alt": "Wooden frames",
            "title": "Wooden frames",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <h2 {...{
      "id": "using-lining-members-to-enhance-structural-stability"
    }}>{`Using lining members to enhance structural stability`}</h2>
    <h3 {...{
      "id": "carton-with-internally-folded-liner"
    }}>{`Carton with internally folded liner`}</h3>
    <p>{`The internally folded liner is made up of a gray board as the frame and covered with coated paper or other paper, with a gap at the bending of the gray board. The whole paper carton can be folded to form a three-dimensional shape for utilization. It can be folded freely and shaped beautifully to reduce the cost of packaging and logistics. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "823px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.084942084942085%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABcRAAAXEQHKJvM/AAACWUlEQVQozx3PaUuTAQDA8eeLRG+LvoAvpBeBLfCozLSMzTxTG7qhc845dfp4TRQt0LxS59H2TDd1bmULrUQlQzQPdB7N+wCPAila8fyjXvw+wE/4friP/bmZ8oRQenQRSIY72PNvY8+Pok8XQU9OON054XKfJkwerU5mY2X1z/7WTnDnayDoX1wIOi1ZwTlHfXDpoze4tjgfFPgtcxpYoUUTSbsmAsl4H8kYwyt9FFbtLawaBR1ZN7Fpw3jXVCAHArsc7+1xdnLOh8EerOrr+HsNLHXlMSvVIvy6+MFEVwXZiitoFVfpUit4qVYwYIrDW5OGszwJmyFa7jKq5PFRD9uBLfn89FQ+P/smd5oS5fcWpbzprJD9NpM84WiShcD8FN36WJJDL9OacQNXfhRSbjhOsxJXZSojdWqcpUqaTJn4Rt+yu7XNxcVPjg+PsOqiWWjXstpXxKc2HVPjPoTx1mKKY0PQhV/DXRTLoOEu7pJ4bEVKpKJ4bKaHtGkjaSjJweno52DvgJOjI6Y8EoNiErPN2Sx35DLWXsbk5CTCQGkSypBLtD1V4DU/YKjgHiPlibjERLw1qf916mOoE024h934Zz5iN6dQrwzBVRjLVHMeEw0ZDLXV4PP5ENyWTHqNj3CLj/GIKkZKlQxVpjIgJvKmNp2RqiSacuN4VmvhtcfD9vIcXxz1TLfomW43YjcnIGZEInW38nlmBmGoRoOrSo2jLI1+MYUBMZnhqid4LOm4q9Nwlqmoy1XxorERn9fD4b/y/h5rY/10FqdgKcxi1OtmY32DzbV1/gJZdL5pSGlemwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/52ce407cd853830e30086767ab304c04/652f5/structural-reinforcement-09.webp 259w", "/static/52ce407cd853830e30086767ab304c04/c29d2/structural-reinforcement-09.webp 518w", "/static/52ce407cd853830e30086767ab304c04/7fbc2/structural-reinforcement-09.webp 823w"],
            "sizes": "(max-width: 823px) 100vw, 823px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/52ce407cd853830e30086767ab304c04/a2ead/structural-reinforcement-09.png 259w", "/static/52ce407cd853830e30086767ab304c04/6b9fd/structural-reinforcement-09.png 518w", "/static/52ce407cd853830e30086767ab304c04/31aff/structural-reinforcement-09.png 823w"],
            "sizes": "(max-width: 823px) 100vw, 823px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/52ce407cd853830e30086767ab304c04/31aff/structural-reinforcement-09.png",
            "alt": "Empty internally folded carton",
            "title": "Empty internally folded carton",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "564px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACyklEQVQ4y32T7U9ScRTHbesvaG39BdXbHrbWWi/abMvWbJPmi5obOXFKhQNJ8IEm0xtsV1EQRS5pcE0QH3hIHoIQMRxMjRTJBMSKNFFDZWso88q9t+ktU7z6e/Xbfudzvt9zzu9k4cefNLK9g2yfEJC1f0NRdCUa+eJxTg6bXT0KmFfKzLns6Id3s6R3joVRNI3jeMDjeHbrIivniohFpWVfunr21LVzp1W1dCSVxHEcwzBymHh4CwlzLpx5eOM8QL0N0u5KnlLgygJFSbbfrt2NQdGT4Gm31dRQ9tUgWnVAv0fhpPfNuhPScB9YmjkoGXmo5m0EidhVgQGxSsQX11U9ysv1qoCxdlZXOWVlbppU/C9MiEcnhtyd9aW0YtpjKuV+HlhRMt8PKBn3xvvlpGX/g/eyri99DxtbpHwul0EHKhitLxg/BhsHqvP1gifoDtFwjNw2juMIgoRs8OdecFYv/mmVrdha4/aWkUaaz6LeUz5imzCzsZ5wOz1G3fthjXLZ2rJsb4/ZZItmyYJeOKVgx6Nh8poJOJVKdb/uBQXtahkU6gODGmBCzvFKmSGdZNoA2cWctW+zR8v+P6rERqJTrtHKoUkFB+YWCArvuISFQx1gfDVhFvHMQnos6MvgD3U7Mhc1KOGQUebrBtm5N/l510cbSsN2dTTgXwwHHZLqiPcdecMIPvxpYkqnnDfJHQBtZtw3poO9krJfk66pvg5X8/MRMTMwbCSiM7tNrEfEYwsMqiImecxrUYMvLfXFY21sp7jc2VZjl9Z80MjSKEYyKkJ8K5kMWHtmzV1OaaWBX2QEWapaelM5tQtgjSrq3FoojZHB+/xabDFgUulEPH0jd1BcVV1IqSnKNzTxPupezfvHMdJPcpBfmpvRAkxjA9sBCUa0ihmPM760gB23GBkZcBwP+jwLQf/W5mb6wHhIRkWGYxl2ji7GHws18Nd8nFsaAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2be23b19390be3f68c116354278c5ef3/652f5/structural-reinforcement-08.webp 259w", "/static/2be23b19390be3f68c116354278c5ef3/c29d2/structural-reinforcement-08.webp 518w", "/static/2be23b19390be3f68c116354278c5ef3/0a341/structural-reinforcement-08.webp 564w"],
            "sizes": "(max-width: 564px) 100vw, 564px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2be23b19390be3f68c116354278c5ef3/a2ead/structural-reinforcement-08.png 259w", "/static/2be23b19390be3f68c116354278c5ef3/6b9fd/structural-reinforcement-08.png 518w", "/static/2be23b19390be3f68c116354278c5ef3/ba4d9/structural-reinforcement-08.png 564w"],
            "sizes": "(max-width: 564px) 100vw, 564px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/2be23b19390be3f68c116354278c5ef3/ba4d9/structural-reinforcement-08.png",
            "alt": "Internally Folded liner carton with object",
            "title": "Internally Folded liner carton with object",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <h3 {...{
      "id": "specially-shaped-cartons-for-component-placement"
    }}>{`Specially shaped cartons for component placement`}</h3>
    <p>{`To place objects, unique chambers are often made in the inner layer of the box. The structure provides cushioning of the product, avoiding issues such as poor environmental protection and poor recycling performances of external cushioning materials. Since no cushioning material is needed, the packing process will be much simpler and more effective. The process of recycling these packing materials will be more convenient. `}</p>
    <p>{`The process of producing the specially sectioned cartons takes around 7-10 days. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "834px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.66795366795367%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABcRAAAXEQHKJvM/AAACs0lEQVQoz02T6U9TURDF+wf5iW/GaNC4EiNGExc0Ehu0tojUIlFBUJFoi0BZFNQUbRQVbS2KZREIqGGxLEpZVCrIppQKUQPYwOO9+zOvCzrJXSZz5sydzLkaQKeuSV+/bqCzURf1Ab1Q5ESgTAgBIFQL/pxm3j+q+iwFF4nEVk/NxLCXWns+dy9qaay0Egz4mPv0mnAYhKKodzEfGGOmrwl/Vw3T3W7mxodosWXhe1cfwckhUk1Hw1PJfHS9VGaKlx5e0UofX1ik6aYiKfDBLSmKLAGymrA4OyWmOpzic6Md77N8XFeTcOUepd5q4Ptwz2pxjc16SWRod4rnxaeFszBVPDbrxIDrmvC33BT+bpeQgvMiMOpluM0h3jvMovTUHirO7KXdlk5VjhZ3gYFX5SZ++r+GWx7sfktKwnZyUg9TaU7m1sVjPMnTM1htwd9axuLMCCPdr3hdbqClPA3t1rVoN8XwODeJqqvHcVzTUVeSTHNFJsGFX2iUpQUcRSaOxMdScD6JmtIzOK1pPMvT0d9gQ5Zl5BWJ3mornsosDAlx7Fq3hqzDW/A8yKam8BSOvJP4PPWsrCyjWQ4u0OvI5XbWEfQH43hgMfLInEy+aT9fBnqI2rSvB0/lBVorMkhJ2ML+jTFYjQfotGfywmpk7ttIuGV1G22vwmM38SRPx2XDboyHNmNK3MHE2GhEEgqqKvrq7jDgzKE0Q0v8hhhKTu/jZaGehptpfOlp+kcoLf1hqOY6LbdSOKfdRkZiLBf0e2lr7wiBFDk0aH4HJqmz5VBdchZ3aTpNN1J5Y7/M7x+Tq1rUqKMOySIwgrv4BNlJm7lnMfLJ20VEzyGgEsEN9nu5b0mn11lIX+1dZseG/utChF8YBfe3Paez2bUq6ihZtLqaFCL1tDL+4Q2ytPxfLPxT/gJJU8X/0sejZQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/f77881a3d1a871649c4bae65e75e4a39/652f5/structural-reinforcement-10.webp 259w", "/static/f77881a3d1a871649c4bae65e75e4a39/c29d2/structural-reinforcement-10.webp 518w", "/static/f77881a3d1a871649c4bae65e75e4a39/e1262/structural-reinforcement-10.webp 834w"],
            "sizes": "(max-width: 834px) 100vw, 834px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/f77881a3d1a871649c4bae65e75e4a39/a2ead/structural-reinforcement-10.png 259w", "/static/f77881a3d1a871649c4bae65e75e4a39/6b9fd/structural-reinforcement-10.png 518w", "/static/f77881a3d1a871649c4bae65e75e4a39/5d72a/structural-reinforcement-10.png 834w"],
            "sizes": "(max-width: 834px) 100vw, 834px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/f77881a3d1a871649c4bae65e75e4a39/5d72a/structural-reinforcement-10.png",
            "alt": "Segmented carton",
            "title": "Segmented carton",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "eva-foam-slot"
    }}>{`EVA foam slot`}</h3>
    <p>{`Eva foam slot has numerous advantages, including waterproof, corrosion resistance, antibacterial, non-toxic, odor-free, non-polluting, strong and flexible, good shock resistance and buffer performance, also excellent heat and sound insulation capabilities. They are also easy to process: cutting, stamping, pressing, splitting, molding, gluing, fitting, padding, and other processes can be done with ease. `}</p>
    <p>{`The process of producing an EVA foam block takes around 10-15 days. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "460px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.52895752895753%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC40lEQVQozx3QaUhTAQDA8deHPhZJVhShYs1j85ivHeqmmxsO93a4vWPvnO6du97eDrVpJHZAfQkq1AgCvwQJBkFBB1gZaVRqFHY4SE0/JFYU0U1ki4T/9x/8gbVX91dzD9cWZnKTo6+f3Hnz/MHyi4cruZnl+dmV+aeLL2dzc9PPpienp+4+mrg5NX5l4trI/MTF3J2RuRuDwK/V+eGh0+o6ncXugBnOz/BOhKozmqrq9Jo6vaqytrwabHb63bTgZiTQbFfV6G9evbT++fWXt8+BH+8WUZIGAEBVfcDDhFt81JaCHVsLCncX7dtbWl60X71rb8m27TtbERqPdpVWVAObNp8dGsznf//5/h5YW5zzUJwJgr2MRMV7IJIztrr9XBwRFFRKIrzchnWAZpubETqTh+BQvKSiluGk/M+P699WgWfTU06S8wTDVg8WVHo7lF46kSVi3VgkjUczeLQrEMkwSjaY7GMSfVS8V6M36Zrsa0tzfz8tAeO3b9nQDkxKteEhqxv10LyLZJ1EyIEFW2Ha5iftMGn3ETZvwOYNOOCg0eooUWvHr1/Jf10GRi+PmX0UJiUpuQcVEm6SgwgWIkIQyUIk62HE9s4IzMVRLh4QEkQ4bW8PFBaXnTlzav3DC+DU0HAzzATCKSKaoeSejmQfFevGpXRATGHSRmIqIKWoWDctHwzKWRfB71FpWFH48/YxkD1y3OKnNw4l8EgaD6cRIYHyCZSTUVHxszGElXEhSUppQkxR0W5fKA5a2vwUO3bhGEDwUbOH9LEy8h9JI5wC80lMyiB8yscp3lDCzUSdpARREScuunDBx0RdpOjtDFc2GoBz54ebbA5Nra4GNGpBvUGnNxzQGfR6q8nYbACtBq0F1NSrS02VxRZtmd1pbfQ6G7xQgx9SNTYC398vzN671h/DO1trWEeV6KiKe0CsvqgL1g3Qpn6i/iTXcjTYpPAuZSAZOdFLHU7hWQXpkiusln9NISeci5bkXgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3995dc3d644d28505da3651581f6262c/652f5/structural-reinforcement-12.webp 259w", "/static/3995dc3d644d28505da3651581f6262c/bc10c/structural-reinforcement-12.webp 460w"],
            "sizes": "(max-width: 460px) 100vw, 460px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3995dc3d644d28505da3651581f6262c/a2ead/structural-reinforcement-12.png 259w", "/static/3995dc3d644d28505da3651581f6262c/08a84/structural-reinforcement-12.png 460w"],
            "sizes": "(max-width: 460px) 100vw, 460px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/3995dc3d644d28505da3651581f6262c/08a84/structural-reinforcement-12.png",
            "alt": "EVA Foam slot",
            "title": "EVA Foam slot",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "blister-tray"
    }}>{`Blister tray`}</h3>
    <p>{`It is also called a blister inner tray. The blister process is used to make the plastic hard sheet into specific grooves. The products can be placed in the grooves for protection and aesthetics. There are also tray packages for transport with thicknesses not more than 1.55 mm. They are usually made up of plastic, such as PVC, PET, PP, PS and flocking sheets, gold-plated sheets, and anti-static sheets.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "769px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "37.83783783783784%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABcRAAAXEQHKJvM/AAACNklEQVQozx3D209SAQDAYVbqvCF4lHOORw08iSh3QUBFBZS7dkw0ZEJqCRaJV9BmW1trq3XZeuqpl2r1/8VrL+fX1rd9Bkkea4uS0rYMi+1hwdK2DFnagiC0B4wDbZNxoH33Tuef1zfnf798/sTL21d6s9ni6uKM22aDVuOI02qJZr3C7dkhv7++xSBKo0zanYQiURwOF8qIgslsorenh/6+fjo6umheXKAdtPRQvsbSwzqRfBVfvIjqj6NMBRhzzDJ4z0njeB+D0+XT89q2vl2q6HmtoCdSGX02OKer6qQuSbLe1dmlt66v9euP37n88Es/f/eD8/c/efb6GyvaEc7IKp75BP7lLG9u6hh2y08o7u2jFYqsbxbI5jdIZXIk01kSaym8vgBXV5fk906IFV4Q26qT3D1nZfMYz5LGdDD2v+qLclp7jGEtvU6hWKFYPmR7t0xuQyOZzrGaTJNdf4DVNkHjeY2V3A7ToRTucBpfOIXdG0V1RbB7FpgJJhBtbqqVRxhkScZqVfH4gizHk+S1HbRCia1iGa8/SHd3N43aAfOxLNPBBK5AHIdnkfH7XkZVN+L4FOOTXoZG7OxqGQyKoiDLMkOCgDBoRpQknO5ZAqEoJpOZ3t4+GtUKoeUsk/5VRia8jKg+JKsL0erELKtYxmfoE2zkUmkMkjSGJCpIooxleBiz2YzRaMTY34/NNoGijNI6OaK095RMbpNYPMlcOEpgbp5gOIrHO8vC/CI+f4T9coV/OYwPS6gLUPwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3dd98b02cfcdf77d99f48a611e090819/652f5/structural-reinforcement-13.webp 259w", "/static/3dd98b02cfcdf77d99f48a611e090819/c29d2/structural-reinforcement-13.webp 518w", "/static/3dd98b02cfcdf77d99f48a611e090819/85eee/structural-reinforcement-13.webp 769w"],
            "sizes": "(max-width: 769px) 100vw, 769px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/3dd98b02cfcdf77d99f48a611e090819/a2ead/structural-reinforcement-13.png 259w", "/static/3dd98b02cfcdf77d99f48a611e090819/6b9fd/structural-reinforcement-13.png 518w", "/static/3dd98b02cfcdf77d99f48a611e090819/227ba/structural-reinforcement-13.png 769w"],
            "sizes": "(max-width: 769px) 100vw, 769px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/3dd98b02cfcdf77d99f48a611e090819/227ba/structural-reinforcement-13.png",
            "alt": "Blister tray",
            "title": "Blister tray",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      